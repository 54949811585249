.landingPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    font-family: 'Orbitron', sans-serif;
}

.mainTitle {
    font-size: 75px;
    margin: 0;
    font-family: 'Lobster', cursive;
    margin-top: 10vh;
}

.mainTag {
    font-size: 20px;
}

.landingButtons {
    height: 60px;
    width: 120px;
    background-color: white;
    color: #00B2FF;
    font-size: 25px;
    border: none;
    border-radius: 10px;
    font-family: 'Orbitron', sans-serif;
    margin: 10px;
    box-shadow: 2px 2px 10px 1px black;
}

.one:hover {
    cursor: pointer;
    transform: scale(1.2, 1.2);
}

.two:hover {
    cursor: pointer;
    transform: scale(1.2, 1.2);
}

.landingButton {
    margin-top: 30vh;
}

/* Full screen */
@media only screen and (min-width: 992px) {
    .mainTitle {
        font-size: 150px;
        margin-top: 15vh;
    }

    .mainTag {
        font-size: 30px;
    }

    .landingButtons {
        width: 180px;
        margin: 30px;
    }

    .landingButton {
        margin-top: 20vh;
    }
}