.userInterface {
    min-height: 85vh;
    background-color: white;
    border-radius: 20px;
    margin-top: 1vh;
    margin-right: 5vw;
    margin-left: 5vw;
    box-shadow: 3px 3px 10px 1px black;
}

.gauges {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 150px;
    background-color: #00B2FF;
    border-radius: 20px;
    margin-right: 10vw;
    margin-left: 10vw;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 2px 2px 7px 1px black;
}

.uiTitle {
    font-family: 'Orbitron', sans-serif;
    color: #00B2FF;
    font-size: 15px;
    margin: 0px;
    padding-left: 10px;
    padding-top: 30px;
    padding-bottom: 20px;
    text-align: left;
}

.weekGoalMet {
    font-family: 'Orbitron', sans-serif;
    color: #00B2FF;
    font-size: 13px;
}

.weeksAmount {
    font-weight: bold;
    color: #266580;
}

.waterGoal {
    background-color: #00B2FF;
    font-family: 'Orbitron', sans-serif;
    font-weight: bold;
    color: white;
    font-size: 20px;
    padding-top: 1px;
    padding-bottom: 1px;
}

.goalText {
    margin-top: 10px;
    margin-bottom: 10px;
}

.uiButtons {
    height: 60px;
    width: 120px;
    background-color: #00B2FF;
    color: white;
    font-size: 20px;
    border: none;
    border-radius: 10px;
    font-family: 'Orbitron', sans-serif;
    margin: 10px;
    margin-top: 20px;
    box-shadow: 2px 2px 10px 1px black;
}

.uiButtons:hover {
    cursor: pointer;
    transform: scale(1.2, 1.2);
}

.textMe {
    display: flex;
   
}

.textInput {
    font-family: 'Orbitron', sans-serif;
    font-size: 20px;
    color: white;
    height: 40px;
    min-width: 110px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-top: 20px;
    box-shadow: 2px 2px 7px 1px black;
}

.textInfo {
    width: 50vw;
    font-size: 14px;
    padding-top: 5px;
    font-family: 'Orbitron', sans-serif;
    margin-left: auto;
    margin-right: auto;
}

.start {
    background-color: green;
}

.stop {
    background-color: red;
}

/* Full screen */
@media only screen and (min-width: 992px) {
    .userInterface {
        min-height: 80vh;
        margin-right: 25vw;
        margin-left: 25vw;
    }

    .uiButtons {
        margin-left: 30px;
        margin-right: 30px;
        margin-top: 40px;
    }

    .uiTitle {
        font-size: 30px;
        padding-left: 30px;
        padding-bottom: 30px;
    }

    .goalText {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .waterGoal {
        font-size: 30px;
    }

    .weekGoalMet {
        font-size: 20px;
    }

    .textInfo {
        width: 30vw;
        font-size: 14px;
        padding-top: 20px;
    }
}