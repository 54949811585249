@import url(https://fonts.googleapis.com/css?family=Anton|Lobster|Orbitron:400,900&display=swap);
.landingPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    font-family: 'Orbitron', sans-serif;
}

.mainTitle {
    font-size: 75px;
    margin: 0;
    font-family: 'Lobster', cursive;
    margin-top: 10vh;
}

.mainTag {
    font-size: 20px;
}

.landingButtons {
    height: 60px;
    width: 120px;
    background-color: white;
    color: #00B2FF;
    font-size: 25px;
    border: none;
    border-radius: 10px;
    font-family: 'Orbitron', sans-serif;
    margin: 10px;
    box-shadow: 2px 2px 10px 1px black;
}

.one:hover {
    cursor: pointer;
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2);
}

.two:hover {
    cursor: pointer;
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2);
}

.landingButton {
    margin-top: 30vh;
}

/* Full screen */
@media only screen and (min-width: 992px) {
    .mainTitle {
        font-size: 150px;
        margin-top: 15vh;
    }

    .mainTag {
        font-size: 30px;
    }

    .landingButtons {
        width: 180px;
        margin: 30px;
    }

    .landingButton {
        margin-top: 20vh;
    }
}
.roundButton {
    background-color: #00B2FF;
    margin: 20px 5px 20px 20px;
    width: 40px;
    height: 40px;
    border-radius: 30%;
    border: 5px solid white;
    outline: none;
    /* transition: all .2s cubic-bezier(0, 1.26, .8, 1.28); */
    /* box-shadow: 2px 2px 7px 1px black; */
  }
   
/* .roundButton:hover {
    background-color: #00B2FF;
    cursor: pointer;
    border-color: #005980;
    transform: scale(1.2, 1.2);
} */
   
/* .roundButton:active {
    border-color: #005980;
    background-color: #FFF;
} */

.waterDrop {
    height: 20px;
}

.buttonContainer {
    display: flex;
    flex-direction: row;

}

.menuLink {
    display: flex;
    flex-direction: row;
}

.linkText {
    color: white;
    margin-right: 10px;
    margin-top: 26px;
    font-weight: 800;
    font-size: 20px;
}

.linkText:hover {
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2);
}

/* Full screen */
@media only screen and (min-width: 992px) {
    .roundButton {
        width: 50px;
        height: 50px;
    }

    .linkText {
        margin-top: 32px;
    }
}
.learnPage {
    min-height: 80vh;
    background-color: white;
    border-radius: 20px;
    margin-top: 5vh;
    margin-right: 10vw;
    margin-left: 10vw;
    box-shadow: 3px 3px 10px 1px black;
}

.learnButtons {
    height: 60px;
    width: 120px;
    background-color: #00B2FF;
    color: white;
    font-size: 25px;
    border: none;
    border-radius: 10px;
    font-family: 'Orbitron', sans-serif;
    margin: 10px;
    box-shadow: 2px 2px 10px 1px black;
}

.one {
    margin-top: 20px;
}

.two {
    margin-top: 50px;
}

.factContainer {
    padding-top: 10px;
}

.learnFact {
    color: #00B2FF;
    font-family: 'Orbitron', sans-serif;
    margin-left: 10px;
    margin-right: 10px;
    line-height: 1.25;
}

.learnTitle {
    font-family: 'Orbitron', sans-serif;
    color: #00B2FF;
    padding-top: 20px;
}

.learnImg {
    height: 60px;
}

/* Full screen */
@media only screen and (min-width: 992px) {
    .learnPage {
        min-height: 550px;
        margin-top: 10vh;
        margin-right: 15vw;
        margin-left: 15vw;
    }

    .learnButtons {
        width: 180px;
    }

    .one {
        margin-top: 20px;
    }

    .two {
        margin-top: 45px;
    }

    .learnImg {
        height: 100px;
    }

    .learnTitle {
        font-size: 40px;
        padding-top: 30px;
    }

    .learnFact {
        margin-left: 50px;
        margin-right: 50px;
        line-height: 1.25;
        font-size: 20px;
    }
}
.loginButtons {
    height: 60px;
    width: 120px;
    background-color: #00B2FF;
    color: white;
    font-size: 25px;
    border: none;
    border-radius: 10px;
    font-family: 'Orbitron', sans-serif;
    margin: 10px;
    margin-top: 60px;
    box-shadow: 2px 2px 10px 1px black;
}

.loginButtons:hover {
    cursor: pointer;
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2);
}

.loginPage {
    min-height: 425px;
    background-color: white;
    border-radius: 20px;
    margin-top: 5vh;
    margin-right: 10vw;
    margin-left: 10vw;
    box-shadow: 3px 3px 10px 1px black;
}

.loginTitle {
    font-family: 'Orbitron', sans-serif;
    color: #00B2FF;
    margin: 0px;
    padding-top: 30px;
    padding-bottom: 20px;
}

.loginForm {
    font-family: 'Orbitron', sans-serif;
    color: #00B2FF;
}

.loginInput {
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 1px solid #00B2FF;
    border-radius: 10px;
}

.loginLabel {
    font-size: 12px;
    margin-left: 20px;
    margin-right: 20px;
}

.error {
    color: red;
    margin-left: 10px;
    margin-right: 10px;
}

.demo {
    font-size: 12px;
}

/* Full screen */
@media only screen and (min-width: 992px) {
    .loginPage {
        min-height: 500px;
        margin-top: 10vh;
        margin-right: 35vw;
        margin-left: 35vw;
    }

    .loginTitle {
        padding-top: 40px;
        padding-bottom: 20px;
        font-size: 40px;
    }

    .loginInput {
        padding: 16px 80px;
        margin: 15px 0;
    }

    .loginButtons {
        width: 180px;
        font-size: 25px;
        margin-top: 50px;
    }
}


.registerTitle {
    font-family: 'Orbitron', sans-serif;
    color: #00B2FF;
    margin: 0px;
    padding-top: 30px;
    padding-bottom: 20px;
}

.registerPage {
    min-height: 400px;
    background-color: white;
    border-radius: 20px;
    margin-top: 5vh;
    margin-right: 10vw;
    margin-left: 10vw;
    box-shadow: 3px 3px 10px 1px black;
}

.registerButtons {
    height: 60px;
    width: 120px;
    background-color: #00B2FF;
    color: white;
    font-size: 25px;
    border: none;
    border-radius: 10px;
    font-family: 'Orbitron', sans-serif;
    margin: 10px;
    margin-top: 50px;
    margin-bottom: 40px;
    box-shadow: 2px 2px 10px 1px black;
}

.registerButtons:hover {
    cursor: pointer;
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2);
}

.registerInput {
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 1px solid #00B2FF;
    border-radius: 10px;
}

.regLabel {
    display: flex;
    flex-direction: column;

   
    font-size: 12px;
    margin-left: 20px;
    margin-right: 20px;
    font-family: 'Orbitron', sans-serif;
    color: #00B2FF;
}

.error {
    color: red !important;
}

.errorBox {
    font-family: 'Orbitron', sans-serif;
    color: #00B2FF;
}

/* Full screen */
@media only screen and (min-width: 992px) {
    .registerPage {
        min-height: 500px;
        margin-top: 10vh;
        margin-right: 35vw;
        margin-left: 35vw;
    }

    .registerTitle {
        padding-top: 40px;
        font-size: 40px;
    }

    .registerInput {
        padding: 16px 80px;
        margin: 15px 0;
        margin-left: 20px;
        margin-right: 20px;
    }

    .registerButtons {
        width: 180px;
        margin-top: 45px;
    }
}
.userInterface {
    min-height: 85vh;
    background-color: white;
    border-radius: 20px;
    margin-top: 1vh;
    margin-right: 5vw;
    margin-left: 5vw;
    box-shadow: 3px 3px 10px 1px black;
}

.gauges {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 150px;
    background-color: #00B2FF;
    border-radius: 20px;
    margin-right: 10vw;
    margin-left: 10vw;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 2px 2px 7px 1px black;
}

.uiTitle {
    font-family: 'Orbitron', sans-serif;
    color: #00B2FF;
    font-size: 15px;
    margin: 0px;
    padding-left: 10px;
    padding-top: 30px;
    padding-bottom: 20px;
    text-align: left;
}

.weekGoalMet {
    font-family: 'Orbitron', sans-serif;
    color: #00B2FF;
    font-size: 13px;
}

.weeksAmount {
    font-weight: bold;
    color: #266580;
}

.waterGoal {
    background-color: #00B2FF;
    font-family: 'Orbitron', sans-serif;
    font-weight: bold;
    color: white;
    font-size: 20px;
    padding-top: 1px;
    padding-bottom: 1px;
}

.goalText {
    margin-top: 10px;
    margin-bottom: 10px;
}

.uiButtons {
    height: 60px;
    width: 120px;
    background-color: #00B2FF;
    color: white;
    font-size: 20px;
    border: none;
    border-radius: 10px;
    font-family: 'Orbitron', sans-serif;
    margin: 10px;
    margin-top: 20px;
    box-shadow: 2px 2px 10px 1px black;
}

.uiButtons:hover {
    cursor: pointer;
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2);
}

.textMe {
    display: flex;
   
}

.textInput {
    font-family: 'Orbitron', sans-serif;
    font-size: 20px;
    color: white;
    height: 40px;
    min-width: 110px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-top: 20px;
    box-shadow: 2px 2px 7px 1px black;
}

.textInfo {
    width: 50vw;
    font-size: 14px;
    padding-top: 5px;
    font-family: 'Orbitron', sans-serif;
    margin-left: auto;
    margin-right: auto;
}

.start {
    background-color: green;
}

.stop {
    background-color: red;
}

/* Full screen */
@media only screen and (min-width: 992px) {
    .userInterface {
        min-height: 80vh;
        margin-right: 25vw;
        margin-left: 25vw;
    }

    .uiButtons {
        margin-left: 30px;
        margin-right: 30px;
        margin-top: 40px;
    }

    .uiTitle {
        font-size: 30px;
        padding-left: 30px;
        padding-bottom: 30px;
    }

    .goalText {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .waterGoal {
        font-size: 30px;
    }

    .weekGoalMet {
        font-size: 20px;
    }

    .textInfo {
        width: 30vw;
        font-size: 14px;
        padding-top: 20px;
    }
}
.yourGoal {
    background-color: #0CE0E8;
    height: 60px;
    margin-top:10px;
    margin-bottom: 10px;
    box-shadow: 1px 1px 7px 1px black;
}

.waterConsumed {
    background-color:#0DFFC4;
    height: 60px;
    margin-top:10px;
    margin-bottom: 10px;
    box-shadow: 1px 1px 7px 1px black;
}

.chartContainer {
   min-height: 100px;
}

.goalMet {
    font-size: 30px;
    font-family: 'Orbitron', sans-serif;
    color: white;
}

.goalName {
    padding-top: 20px;
    font-family: 'Orbitron', sans-serif;
    color: white;
    font-weight: 600;
}
.contactPage {
    min-height: 20vh;
    background-color: white;
    border-radius: 20px;
    margin-top: 5vh;
    margin-right: 10vw;
    margin-left: 10vw;
    box-shadow: 3px 3px 10px 1px black;
}

.contactTitle {
    font-family: 'Orbitron', sans-serif;
    color: #00B2FF;
    padding-top: 20px;
}

.contactInfo {
    font-family: 'Orbitron', sans-serif;
    color: #00B2FF;
}

/* Full screen */
@media only screen and (min-width: 992px) {
    .learnPage {
        height: 500px;
        margin-top: 10vh;
        margin-right: 15vw;
        margin-left: 15vw;
    }
}
/* fonts */
/* font-family: 'Lobster', cursive;
font-family: 'Anton', sans-serif;
font-family: 'Orbitron', sans-serif; */

.appBody {
    min-height: 100vh;
    min-width: 100vw;
    background-image: url('https://lh3.googleusercontent.com/JyC0UxDj9mLGokSFgE7RqRIpNznwbXD8VwlpQk3_lyFDi5j-mKlFqZ2eBYzjaW2tdWyhumO3DIICu1vYBL8-alHk9No8sPlppxBrMOIryVBmKyfzA0vLcWRqhzkES4ghgz_hoNQdnorDelxMBJH3jDvnOzW557mH127urNIKjnbfTfSIjW1NesLdFmIpIjjHkKBVLhePrpXijniowZQuz4rbbhn9alk9hOkfUC-4snUBWvbP5ZtPcDNuxJYBZy5LvCwYYNTqYGKBVCn_9atteWhi024Q-NsrAyYMy9DAAEEapLU6ciM7KGGKYMIkL6EIDO3MisBakqtvDe79kfaLei-vPDIW6forK9zadTcZtgJbvYI7vy-GvfepXFCkYpBdp7yxtwBxasljlsfZYd3AkRN-oE7OHQTR24WewjLlo0Px2KXTddIqaeOnS3tqqw10aDvVHywmA3LYy2Wg45M2wif7jGL6OfL2ZAX4dtOH1o3MrCqa9Xnaj3q7wcZg41aTZKVI86tWZBubB1zCkGhM9RsVmYcRhAFD4Jm3UuulHDzKJufPpF0atiWKg0vndD7DQUWMWf63R_gZDApyGV0XL-ts2KCm6QViajrIPCvY-l06nMkD7wMBjhEvAL5Zy3A2czHu5p3vZ7GSM0gdiH9Dnxp4zVlr56JZ9CE7CY7V7xYXkLgnWXQUlo2pMKP9iAUTA7iOtDt6Vwm5qFyPlVY-2JF0GwKonEWLi8IxZ8kemTpeBZW5=w1920-h704-no');
    background-color: white;
    background-repeat: no-repeat;
    background-size: cover;
}

.mainDisplay {
    text-align: center;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

