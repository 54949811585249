.loginButtons {
    height: 60px;
    width: 120px;
    background-color: #00B2FF;
    color: white;
    font-size: 25px;
    border: none;
    border-radius: 10px;
    font-family: 'Orbitron', sans-serif;
    margin: 10px;
    margin-top: 60px;
    box-shadow: 2px 2px 10px 1px black;
}

.loginButtons:hover {
    cursor: pointer;
    transform: scale(1.2, 1.2);
}

.loginPage {
    min-height: 425px;
    background-color: white;
    border-radius: 20px;
    margin-top: 5vh;
    margin-right: 10vw;
    margin-left: 10vw;
    box-shadow: 3px 3px 10px 1px black;
}

.loginTitle {
    font-family: 'Orbitron', sans-serif;
    color: #00B2FF;
    margin: 0px;
    padding-top: 30px;
    padding-bottom: 20px;
}

.loginForm {
    font-family: 'Orbitron', sans-serif;
    color: #00B2FF;
}

.loginInput {
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 1px solid #00B2FF;
    border-radius: 10px;
}

.loginLabel {
    font-size: 12px;
    margin-left: 20px;
    margin-right: 20px;
}

.error {
    color: red;
    margin-left: 10px;
    margin-right: 10px;
}

.demo {
    font-size: 12px;
}

/* Full screen */
@media only screen and (min-width: 992px) {
    .loginPage {
        min-height: 500px;
        margin-top: 10vh;
        margin-right: 35vw;
        margin-left: 35vw;
    }

    .loginTitle {
        padding-top: 40px;
        padding-bottom: 20px;
        font-size: 40px;
    }

    .loginInput {
        padding: 16px 80px;
        margin: 15px 0;
    }

    .loginButtons {
        width: 180px;
        font-size: 25px;
        margin-top: 50px;
    }
}

