.registerTitle {
    font-family: 'Orbitron', sans-serif;
    color: #00B2FF;
    margin: 0px;
    padding-top: 30px;
    padding-bottom: 20px;
}

.registerPage {
    min-height: 400px;
    background-color: white;
    border-radius: 20px;
    margin-top: 5vh;
    margin-right: 10vw;
    margin-left: 10vw;
    box-shadow: 3px 3px 10px 1px black;
}

.registerButtons {
    height: 60px;
    width: 120px;
    background-color: #00B2FF;
    color: white;
    font-size: 25px;
    border: none;
    border-radius: 10px;
    font-family: 'Orbitron', sans-serif;
    margin: 10px;
    margin-top: 50px;
    margin-bottom: 40px;
    box-shadow: 2px 2px 10px 1px black;
}

.registerButtons:hover {
    cursor: pointer;
    transform: scale(1.2, 1.2);
}

.registerInput {
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 1px solid #00B2FF;
    border-radius: 10px;
}

.regLabel {
    display: flex;
    flex-direction: column;

   
    font-size: 12px;
    margin-left: 20px;
    margin-right: 20px;
    font-family: 'Orbitron', sans-serif;
    color: #00B2FF;
}

.error {
    color: red !important;
}

.errorBox {
    font-family: 'Orbitron', sans-serif;
    color: #00B2FF;
}

/* Full screen */
@media only screen and (min-width: 992px) {
    .registerPage {
        min-height: 500px;
        margin-top: 10vh;
        margin-right: 35vw;
        margin-left: 35vw;
    }

    .registerTitle {
        padding-top: 40px;
        font-size: 40px;
    }

    .registerInput {
        padding: 16px 80px;
        margin: 15px 0;
        margin-left: 20px;
        margin-right: 20px;
    }

    .registerButtons {
        width: 180px;
        margin-top: 45px;
    }
}